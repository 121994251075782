<template>
  <div>
    <div class="header">
      <van-nav-bar
        :title="$t('tix.title')"
        left-arrow
        @click-left="$router.go(-1)"
      />
    </div>
    <div v-if="!isLoadingDrawData" class="maincontent">
      <van-form v-model="form" ref="vantForm">
        <van-row>
          <!-- <van-cell :title="$t('tix.biz')" center>
            <template #extra>
              <van-dropdown-menu active-color="#004ea3">
                <van-dropdown-item v-model="bizhong" :options="bzoptions" />
              </van-dropdown-menu>
            </template>
          </van-cell> -->

          <van-cell :title="labelLangerConfigs.lname" center>
            <template #extra>
              <van-dropdown-menu active-color="#004ea3">
                <van-dropdown-item v-model="productvalue" :options="product" />
              </van-dropdown-menu>
            </template>
          </van-cell>

          <template
            v-if="selectedProduct.product_type === WALLET_TYPES.yinhangka"
          >
            <van-field
              :label="$t('czhi.memberName')"
              :placeholder="$t('czhi.enter')"
              v-model="form.$memberName"
              :rules="[{ required: true, message: 'This field is required' }]"
            />
            <van-field
              :label="$t('czhi.memberArea')"
              :placeholder="$t('czhi.enter')"
              v-model="form.$memberArea"
              :rules="[{ required: true, message: 'This field is required' }]"
            />
            <van-field
              :label="$t('czhi.memberBankName')"
              :placeholder="$t('czhi.enter')"
              v-model="form.$memberBankName"
              :rules="[{ required: true, message: 'This field is required' }]"
            />
            <van-field
              :label="$t('czhi.memberBankCardNumber')"
              :placeholder="$t('czhi.enter')"
              v-model="form.$memberBankCardNumber"
              :rules="[{ required: true, message: 'This field is required' }]"
            />
          </template>
          <template v-else>
            <van-field
              v-model="form.url"
              :label="labelLangerConfigs.address"
              :placeholder="$t('tix.enter')"
            />
          </template>
          <van-field
            :label="labelLangerConfigs.number"
            v-model="form.number"
            clearable
            ><template #button>
              <van-button size="small" type="primary" @click.prevent="all">{{
                $t('tix.allbtn')
              }}</van-button>
            </template>
          </van-field>
          <div
            style="
              width: 100%;
              text-align: left;
              padding-left: 13px;
              margin: 10px 0px;
            "
          >
            <span style="color: #969799"
              >{{ $t('tix.text') }}{{ balance }}</span
            >
          </div>
        </van-row>
        <van-button class="submitBtns" @click.prevent="submit">{{
          $t('common.submit')
        }}</van-button>
      </van-form>
    </div>
  </div>
</template>
<script>
import { WALLET_TYPES } from '@/constant/index.js'

export default {
  data() {
    return {
      form: {
        url: '',
        number: 0,
      },
      productvalue: 0,
      product: [],
      bizhong: 1,
      bzoptions: [{ text: 'USDT', value: 1 }],
      balance: 0,
      config: {},
      isLoadingDrawData: false,
      WALLET_TYPES,
    }
  },
  created() {
    this.getinfo()
  },
  methods: {
    async getinfo() {
      try {
        this.isLoadingDrawData = true

        const { data } = await this.$http.get('/home/user/withdraw')
        if (data) {
          if (data.code === 200) {
            this.balance = data.data.balance
            this.wallet = data.data.type
            this.config = data.data.config
            this.product = []
            this.wallet.forEach((type, index) => {
              this.product.push({
                text: type.wallet_name.toUpperCase(),
                value: index,
              })
            })
            if (this.config.withdrawl_opend === '0') {
              this.$toast.fail(this.$t('tix.erroropen'))
            }
            if (!this.config.attime) {
              this.$toast.fail(
                this.$t('tix.errortime') + this.config.withdrawl_times
              )
            }
          }
        }
      } catch (error) {
        this.$toast.fail(this.$t('tix.complate'))
      } finally {
        this.isLoadingDrawData = false
      }
    },
    all() {
      this.form.number = this.balance
    },
    async submit() {
      await this.$refs.vantForm.validate()

      const postform = {
        product_type: this.selectedProduct.product_type,
        product_type_id: this.selectedProduct.product_type_id,
        withdraw_url: this.labelLangerConfigs.withdraw_url,
        withdraw_num: this.form.number,
        service_charge: this.config.withdrawl_fee,
      }

      console.info('111', postform);

      const { data } = await this.$http.post('/home/user/withdraw', postform)
      if (data) {
        if (data.code === 200) {
          this.$toast.success(this.$t('tix.submitok'))
          this.form = {
            url: '',
            number: 0,
          }
          this.getinfo()
        } else {
          this.$toast.fail(this.getlang(data.msg, localStorage.getItem('lang')))
        }
      }
    },
  },
  computed: {
    selectedProduct() {
      return this.wallet[this.productvalue]
    },
    labelLangerConfigs() {
      const { form } = this

      switch (this.selectedProduct.product_type) {
        case WALLET_TYPES.yinhangka:
          return {
            lname: this.$t('tix.lname__yinhangka'),
            number: this.$t('tix.number__yinhangka'),

            withdraw_url: `${form.$memberName} ${form.$memberArea} ${form.$memberBankName} ${form.$memberBankCardNumber}`
          }
        default:
          return {
            lname: this.$t('tix.lname'),
            address: this.$t('tix.address'),
            number: this.$t('tix.number'),

            withdraw_url: this.form.url
          }
      }
    },
  },
}
</script>
<style lang="less" scoped>
.maincontent {
  padding-top: 65px;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  font-size: 0.93333rem;
  height: 100vh;
  .van-cell__title {
    text-align: left;
    font-size: 0.93333rem;
  }
  .van-cell {
    font-size: 0.98667rem;
    height: 3.46667rem;

    &:after {
      border-bottom: 0.02667rem solid var(--border-color);
    }
  }
  .van-dropdown-menu /deep/.van-dropdown-menu__bar {
    box-shadow: none;
    height: 1rem;
    background: transparent !important;
  }
  .van-dropdown-menu /deep/ .van-dropdown-menu__title {
    color: var(--main-text-color);
  }
  /deep/.van-dropdown-item {
    margin-top: 1.1rem;
  }
  .van-field {
    height: 3.4rem;
    font-size: 0.93333rem;
    /deep/.van-field__label {
      color: var(--main-text-color);
    }
  }
  .van-cell__value {
    position: relative;
    overflow: hidden;
    color: #969799;
    text-align: right;
    vertical-align: middle;
    word-wrap: break-word;
  }
  /deep/.van-field__error-message--right,
  /deep/.van-field__control,
  /deep/.van-field__error-message {
    text-align: right;
  }
  .message {
    margin-top: 0.8rem;
    font-size: 1.06667rem;
    color: var(--sub-text-color);
  }
  .submitBtns {
    margin-top: 70%;
    width: 80%;
    letter-spacing: 0.53333rem;
    height: 4rem;
    font-size: 0.93333rem;
    color: #fff;
    background-color: #004ea3;
    border-radius: 0.53333rem;
    border: none;
    box-shadow: 0 0 1.33333rem #004ea3;
  }
}
</style>
